import styled from 'styled-components';
import { Link } from 'react-router-dom';
import React from 'react';
import { breakpoints } from '@naf/theme';

const Wrapper = styled.li`
  padding-top: 32px;

  :first-of-type {
    padding-top: 0;
  }
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const ListElement = styled((props) => <Link {...props} />)`
  cursor: pointer;
  /* Have to use !important here because this element is nested inside tabs*/
  text-decoration: none !important;

  display: flex;
  padding: 32px 0;

  :first-of-type {
    padding-top: 0;
  }

  border-bottom: 1px solid lightgrey;

  &:hover {
    border-radius: 4px;
    /* Have to use !important here because this element is nested inside tabs*/
    color: black !important;
    img {
      transform: scale(1.05);
    }
    h3 {
      &::after {
        width: 100%;
      }
    }
  }

  svg {
    margin-right: 6px;
    padding: 0;
  }
`;

const ExternalLinkListElement = styled.a`
  cursor: pointer;
  /* Have to use !important here because this element is nested inside tabs*/
  text-decoration: none !important;

  display: flex;
  padding: 32px 0;

  :first-of-type {
    padding-top: 0;
  }

  border-bottom: 1px solid lightgrey;

  &:hover {
    border-radius: 4px;
    /* Have to use !important here because this element is nested inside tabs*/
    color: black !important;
    img {
      transform: scale(1.05);
    }
    h3 {
      &::after {
        width: 100%;
      }
    }
  }

  svg {
    margin-right: 6px;
    padding: 0;
  }
`;

const ElementImage = styled.figure`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 2px;
  margin: 0 28px 0 0;
  width: 222px;
  position: relative;
  height: calc(222px / (16 / 9));

  @media (max-width: ${breakpoints.s}) {
    width: 100px;
    height: calc(100px / (16 / 9));
    margin-right: 24px;
    font-size: 0.625rem;
  }

  z-index: 0;

  img {
    transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 105%;
    object-fit: cover;

    @media (max-width: ${breakpoints.s}) {
      height: 102%;
    }
  }
`;

const ElementInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  h3 {
    width: auto;
    position: relative;
    margin: 0;

    @media (min-width: ${breakpoints.s}) {
      max-width: max-content;
    }

    &::after {
      content: '';
      width: 0;
      height: 2px;
      transition: width 0.3s cubic-bezier(0.23, 1, 0.32, 1);
      background-color: #ffdb16;
      position: absolute;
      left: 0;
      bottom: -4px;
    }
  }
`;

const ElementSubInfo = styled.p`
  font-size: 1rem;
  color: #666566;
  margin: 12px 0 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 20px;
`;

const IconWrapper = styled.div`
  display: flex;
  font-size: 1rem;
  line-height: 24px;
  flex-direction: column;
`;

const MotorIconWrapper = styled(IconWrapper)`
  flex-direction: row;

  @media (max-width: ${breakpoints.s}) {
    flex-direction: column;
  }
`;

const IconSet = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  padding-bottom: 5px;
`;

export default {
  Wrapper,
  ListElement,
  ExternalLinkListElement,
  ElementImage,
  ElementInfo,
  ElementSubInfo,
  IconWrapper,
  MotorIconWrapper,
  IconSet,
};
